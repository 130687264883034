<template>
  <v-app>
    <v-system-bar
      class="noprint"
      color="grey darken-2"
      dark
      height="40"
      app
      lights-out
    >
      <v-toolbar-items class="ml-5">
        <v-btn
          href="javascript:window.print()"
          class="title elevation-0 mr-2"
          color="grey darken-1"
          x-large
        >
          <v-icon>mdi-printer</v-icon>&nbsp; พิมพ์
        </v-btn>

        <v-btn
          @click.stop="exportToExcel('exportContent', 'รายงานผลการประเมิน')"
          class="mr-1"
          color="green"
        >
          <v-icon>mdi-file-excel</v-icon>&nbsp; Export to excel
        </v-btn>
      </v-toolbar-items>
    </v-system-bar>

    <div
      id="exportContent"
      style="mso-page-orientation: landscape; overflow-x: scroll; overflow-x: scroll;"
    >
      <div class="page">
        <div align="center">
          <div class="head">
            สรุปผลการประเมิน ตำแหน่ง ผู้อำนวยการวิทยาลัย
          </div>
        </div>
        <table class="text_j table" width="100%">
          <thead class="th">
            <tr>
              <th width="auto" class="text-center th">ลำดับ</th>
              <th width="auto" class="text-center th">
                เลขที่ประจำตัว
              </th>
              <th width="auto" class="text-center th">ประเภท</th>
              <th width="auto" class="text-center th">จังหวัด</th>
              <th width="auto" class="text-center th">
                รหัสบัตรชาชน
              </th>
              <th width="auto" class="text-center th">
                ชื่อ-นามสกุล
              </th>
              <th width="auto" class="text-center th">
                สถานศึกษาต้นสังกัด
              </th>
              <th width="5%" class="text-center th">ภาค ก. (10)</th>

              <th class="text-center th ">
                ภาค ข 1.1 (10 คะแนน) สอจ.
              </th>
              <th class="text-center th">
                ภาค ข 1.2 (25 คะแนน) สอจ.
              </th>
              <th class="text-center th">
                ภาค ข 1.3 (5 คะแนน) สอจ.
              </th>

              <th class="text-center th">
                ภาค ข 1.1 (10 คะแนน) ส่วนกลาง
              </th>
              <th class="text-center th">
                ภาค ข 1.2 (25 คะแนน) ส่วนกลาง
              </th>
              <th class="text-center th">
                ภาค ข 1.3 (5 คะแนน) ส่วนกลาง
              </th>

              <th class="text-center th">
                ภาค ข 1.1 (10 คะแนน) กลั่นกรอง
              </th>
              <th class="text-center th">
                ภาค ข 1.2 (25 คะแนน) กลั่นกรอง
              </th>
              <th class="text-center th">
                ภาค ข 1.3 (5 คะแนน) กลั่นกรอง
              </th>

              <th width="auto" class="text-center th">
                ภาค ข. 2 (20) วิสัยทัศน์
              </th>

              <th width="auto" class="text-center th">
                ส่วน ภาค ค. (30)
              </th>

              <th width="auto" class="text-center th">รวม (100)</th>
              <th width="auto" class="text-center th">ร้อยละ</th>
              <th width="auto" class="text-center th">สรุปผล</th>
            </tr>
          </thead>
          <tbody
            class="td"
            v-for="(item, index) in sedirector_apps"
            :key="item.id"
          >
            <tr>
              <td class="text-center th">{{ index + 1 }}</td>
              <td class="text-center th">
                {{ item.sedirector_app_idno }}
              </td>
              <td class="text-center th">
                <span v-if="item.sedirector_app_type === 'inside'"
                  >ยกเว้นเขต จชต.</span
                >
                <span v-if="item.sedirector_app_type === 'outside'"> จชต.</span>
              </td>
              <td class="text-center th">{{ item.province_name }}</td>
              <td class="text-center th">{{ "'" + item.sedirector_app_idcard }}</td>

              <td class="text-center th">{{ item.fristnames }}</td>
              <td class="text-center th">{{ item.user_firstname }}</td>
              <td class="text-center th" style="background-color: aqua;">
                {{ item.sedirector_app_pvcscore_A }}
              </td>

              <td class="text-center th">
                {{ item.sedirector_app_pvcscore_B }}
              </td>
              <td class="text-center th">
                {{ item.sedirector_app_pvcscore_B_2 }}
              </td>
              <td class="text-center th">
                {{ item.sedirector_app_pvcscore_B_3 }}
              </td>

              <td
                class="text-center th"
                style="background-color: aqua;"
              >
                {{ item.sedirector_app_ipascore_B_a1 }}
              </td>
              <td
                class="text-center th"
                style="background-color: aqua;"
              >
                {{ item.sedirector_app_ipascore_B_a2 }}
              </td>
              <td
                class="text-center th"
                style="background-color: aqua;"
              >
                {{ item.sedirector_app_ipascore_B_a3 }}
              </td>

              <td
                class="text-center th"
                style="background-color: aqua;"
              >
                {{ item.sedirector_app_commitscore_B_a1 }}
              </td>
              <td
                class="text-center th"
                style="background-color: aqua;"
              >
                {{ item.sedirector_app_commitscore_B_a2 }}
              </td>
              <td
                class="text-center th"
                style="background-color: aqua;"
              >
                {{ item.sedirector_app_commitscore_B_a3 }}
              </td>

              <td class="text-center th" style="background-color: aqua;">
                {{ item.sedirector_app_pvcscore_B_4 }}
              </td>

              <td class="text-center th" style="background-color: aqua;">
                {{ item.sedirector_app_score_c1 }}
              </td>

              <td class="text-center th">
                {{
                  calAllscore(
                    item.sedirector_app_pvcscore_A,             
                  item.sedirector_app_commitscore_B_a1,
                  item.sedirector_app_commitscore_B_a2,
                  item.sedirector_app_commitscore_B_a3,
                  item.sedirector_app_pvcscore_B_4,
                  item.sedirector_app_score_c1
                  )
                }}
              </td>

              <td class="text-center th">
                {{
                  calAllscoreAvg(
                    item.sedirector_app_pvcscore_A,             
                  item.sedirector_app_commitscore_B_a1,
                  item.sedirector_app_commitscore_B_a2,
                  item.sedirector_app_commitscore_B_a3,
                  item.sedirector_app_pvcscore_B_4,
                  item.sedirector_app_score_c1
                  )
                }}
              </td>

              <td class="text-center th">
                <span
                  v-if="
                    calAllscoreAvg(
                      item.sedirector_app_pvcscore_A,             
                  item.sedirector_app_commitscore_B_a1,
                  item.sedirector_app_commitscore_B_a2,
                  item.sedirector_app_commitscore_B_a3,
                  item.sedirector_app_pvcscore_B_4,
                  item.sedirector_app_score_c1
                    ) >= 60
                  "
                  >ผ่าน</span
                >
                <span v-else>ไม่ผ่าน</span>
              </td>
            </tr>
          </tbody>
          <tr></tr>
        </table>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    ApiKey: "HRvec2021",
    loading: false,
    user: [],
    sedirector_apps: []
  }),

  async mounted() {
    await this.sweetAlertLoading();
    await this.sedirector_appsQuery();
    Swal.close();
  },

  methods: {
    calAllscore(a, b, c, d, e, f) {
      let aa = parseFloat(a) || 0;
      let bb = parseFloat(b) || 0;
      let cc = parseFloat(c) || 0;
      let dd = parseFloat(d) || 0;
      let ee = parseFloat(e) || 0;
      let ff = parseFloat(f) || 0;
      let result =
        parseFloat(aa) +
        parseFloat(bb) +
        parseFloat(cc) +
        parseFloat(dd) +
        parseFloat(ee) +
        parseFloat(ff);
      return result;
    },

    calAllscoreAvg(a, b, c, d, e, f) {
      let aa = parseFloat(a) || 0;
      let bb = parseFloat(b) || 0;
      let cc = parseFloat(c) || 0;
      let dd = parseFloat(d) || 0;
      let ee = parseFloat(e) || 0;
      let ff = parseFloat(f) || 0;
      let sum =
        parseFloat(aa) +
        parseFloat(bb) +
        parseFloat(cc) +
        parseFloat(dd) +
        parseFloat(ee) +
        parseFloat(ff);
      return sum;
    },

    async sedirector_appsQuery() {
      this.loading = true;
      let result = await this.$http
        .post("sedirector_app_dir.php", {
          ApiKey: this.ApiKey,
          sedirector_app_ptype: 3,
          sedirector_app_year: this.years,
          sedirector_app_time: this.times
        })
        .finally(() => (this.loading = false));
      this.sedirector_apps = result.data;
    },

    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },

  
    exportToExcel(element, filename = "Director") {
      let table = document.getElementById(element);
      let tableHTML = table.outerHTML;

      // Include the CSS styles in the Excel file
      let style = `
                        <style>
  @import url(https://fonts.googleapis.com/css?family=Prompt:400,300|Roboto:400,300&subset=latin,thai);
  
  body {
    font-family:"TH SarabunPSK";
    font-size: 16px;
    font-weight: 250;
  }  
  
 </style>
            `;

      let html = `
                <html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
                <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>Sheet1</x:Name>
                <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
                ${style}
                </head>
                <body>
                    ${tableHTML}
                </body>
                </html>
            `;

      // Create a Blob object for the data
      let blob = new Blob([html], { type: "application/vnd.ms-excel" });

      // Create a download link
      let downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `${filename}.xls`;

      // Trigger the download
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  },
  computed: {
    times() {
      return this.$route.query.times;
    },
    years() {
      return this.$route.query.years;
    },

    date_today_cal() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();

      today = yyyy + "-" + mm + "-" + dd;
      return today;
    }
  },
  props: {
    source: String
  }
};
</script>
<style>
body {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16px;
  font-weight: 250;
}

.u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}
u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

* {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.table,
.th,
.td {
  border-bottom: 0.5px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-collapse: collapse;
}

.text_j {
  border-collapse: collapse;
  text-align: justify;
  text-justify: inter-word;
  line-height: 1;
}
.text_l {
  border-collapse: collapse;
  text-align: left;
  text-justify: inter-word;
  line-height: 1;
}

tfoot tr:hover {
  background-color: initial;
}

tbody tr:hover {
  background-color: inherit;
}

td,
th {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
}

tfoot td {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-weight: bold;
}
.textAlignVer {
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: relative;
  white-space: nowrap;
  margin-bottom: 10px;
}

#verticaltext {
  writing-mode: tb-rl;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  white-space: nowrap;
  display: block;
  overflow: hidden;
  padding: 0;
}

.page {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  width: 50cm;
  min-height: 29.7cm;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 0cm;
  margin-right: auto;
  margin-bottom: 0cm;
  margin-left: auto;
  padding-top: 1.5cm;
  padding-right: 1.5cm;
  padding-bottom: 1.5cm;
  padding-left: 2cm;
}

.head {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 22pt;
  font-weight: bold;
  line-height: 1.5;
}

.regular12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
}

.regular16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
}

.bold16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
}

.blod12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
  font-weight: bold;
}

.blackRegula10 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 12pt;
}

.blackBold18 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 18pt;
  font-weight: bold;
}

.largerCheckbox {
  width: 30px;
  height: 30px;
  color: blue;
}
.td_line {
  border-bottom: 1pt dotted black;
}
/*.subpage {
              height: 247mm;
            }*/

.BlackBold10 {
  font-weight: bold;
}

#content {
  display: table;
}

#pageFooter {
  display: table-footer-group;
}

#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}

@media print {
  .page {
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    padding: initial;
    page-break-after: always;
  }

  .head {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  .regular12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .regular16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .bold16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blod12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blackRegula10 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
  }

  .blackBold18 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  .noprint {
    display: none;
  }

  .gap-10 {
    width: 100%;
    height: 10px;
  }
  .gap-20 {
    width: 100%;
    height: 20px;
  }
  .gap-30 {
    width: 100%;
    height: 30px;
  }
}
</style>
